export const PUB_SUB_EVENTS: any = {
    OPEN_HAMBURGER: 'OPEN_HAMBURGER',
    CLOSE_HAMBURGER: 'CLOSE_HAMBURGER',
    FOCUS_MAIN_NAV_HAMBURGER: 'FOCUS_MAIN_NAV_HAMBURGER',
    ENABLE_SUBNAV_STICKY: 'ENABLE_SUBNAV_STICKY',
    DISABLE_SUBNAV_STICKY: 'DISABLE_SUBNAV_STICKY',
};

export interface ISubscription {
    unsubscribe: () => void;
}

/* Basic implementation for PubSub.
 * For more complicated scenarios switch to a library such as rxjs library https://rxjs.dev/ */
export class PubSub {
    static topics: { [id: string]: any[] } = {};

    static subscribe = (topic: string, listener: (data: any) => void): ISubscription => {
        if (!PubSub.topics.hasOwnProperty(topic)) PubSub.topics[topic] = [];

        const index: number = PubSub.topics[topic].push(listener) - 1;

        return {
            unsubscribe(): void {
                delete PubSub.topics[topic][index];
            },
        };
    };

    static publish = (topic: string, data: any = {}): void => {
        if (!PubSub.topics || !PubSub.topics[topic]) return;

        PubSub.topics[topic].forEach((item: (data: any) => void): void => {
            item(data);
        });
    };

    static unsubscibeAll = (): void => {
        if (!PubSub.topics || Object.keys(PubSub.topics).length === 0) return;

        PubSub.topics = {};
    };
}
