import {
    Breakpoints,
    CssClassNames,
    HeaderHeight,
    MinSpaceBeforeDirectionReverse,
    MoreMenuMinSpace,
    Selectors,
    SubMenuWidth,
} from './constants';
import {
    addListener,
    childrenWithFilter,
    debounce,
    keyCodeArrowDown,
    keyCodeArrowUp,
    keyCodeEnter,
    keyCodeEscape,
    keyCodeSpace,
    keyCodeTab,
    parentsUntil,
    removeListener,
    siblings,
    visibleChildren,
} from '@bapiweb-ux/web-utilities';

// Polyfill for Array.from for IE
import 'core-js/features/array/from';
import { v4 as uuidv4 } from 'uuid';
import { SubNavigation } from './sub-navigation';
import { PubSub, PUB_SUB_EVENTS } from './pubsub';

export class BapiHeader {
    // selector functions
    dq: (selector: string) => HTMLElement = document.querySelector.bind(document);
    qs: (selector: string) => HTMLElement = Element.prototype.querySelector;
    qsa: (selector: string) => NodeListOf<HTMLElement> = Element.prototype.querySelectorAll;
    // elements
    rootContainer: HTMLElement;
    topbarContainer: HTMLElement;
    logoContainer: HTMLDivElement;
    brand: HTMLDivElement;
    separator: HTMLDivElement;
    hamburgerMenuContainer: HTMLDivElement;
    hamburgerMenuButton: HTMLButtonElement;
    leftMenu: HTMLUListElement;
    rightMenu: HTMLUListElement;
    moreMenu: HTMLLIElement;
    moreMenuList: HTMLUListElement;
    moreMenuButton?: HTMLButtonElement;
    thisSubNav?: HTMLElement;
    IsMoreMenuAdjustmentsInProgress: boolean;
    subNavigation: SubNavigation;
    meControl: HTMLDivElement;
    meControlOptions: any;
    hasMultiColumnMenu: boolean;

    public load(): void {
        document.addEventListener('DOMContentLoaded', (): void => {
            if (document.querySelector(Selectors.ROOT_CONTAINER)) {
                this._findDOMElements();

                this._addEventHandlers();

                window.dispatchEvent(new Event('resize'));

                window.addEventListener('unload', this.onUnmountEventHandlers);
            }
        });
    }

    private _findDOMElements(): void {
        this.rootContainer = this.dq(Selectors.ROOT_CONTAINER) as HTMLElement;

        this.topbarContainer = this.dq(Selectors.TOPBAR_CONTAINER) as HTMLDivElement;

        this.logoContainer = this.qs.call(this.rootContainer, Selectors.LOGO) as HTMLDivElement;

        this.brand = this.qs.call(this.rootContainer, Selectors.BRAND) as HTMLDivElement;

        this.separator = this.qs.call(this.rootContainer, Selectors.SEPARATOR) as HTMLDivElement;

        this.leftMenu = this.qs.call(this.rootContainer, Selectors.MENU_LEFT) as HTMLUListElement;

        this.rightMenu = this.qs.call(this.rootContainer, Selectors.MENU_RIGHT) as HTMLUListElement;

        this.hamburgerMenuButton = this.qs.call(this.rootContainer, Selectors.HAMBURGER_MENU) as HTMLButtonElement;

        this.hamburgerMenuContainer = this.qs.call(this.rootContainer, Selectors.HAMBURGER_CONTAINER) as HTMLDivElement;

        this.moreMenu = this.qs.call(this.rootContainer, Selectors.MORE_MENU) as HTMLLIElement;

        this.moreMenuList = this.qs.call(this.rootContainer, Selectors.MORE_MENU_LIST) as HTMLUListElement;

        this.moreMenuButton = this.qs.call(this.rootContainer, Selectors.MORE_MENU_BUTTON) as HTMLButtonElement;

        this.thisSubNav = this.dq(Selectors.ROOT_CONTAINER_SUBNAV) as HTMLDivElement;

        this.meControl = this.dq(Selectors.ME_CONTROL) as HTMLDivElement;

        if (this.thisSubNav) {
            this.subNavigation = new SubNavigation();
            this.subNavigation.load();
        }

        this.hasMultiColumnMenu = this.qsa.call(this.rootContainer, "[data-multi-column='5']").length > 0;
    }

    private _addEventHandlers(): void {
        // Bind & Register Event Handlers
        this.onHandleMenuClick = this.onHandleMenuClick.bind(this);
        this.onHandleMenuKeydown = this.onHandleMenuKeydown.bind(this);
        this.onHandleFocus = this.onHandleFocus.bind(this);
        this.onHandleHamburgerMenuClick = this.onHandleHamburgerMenuClick.bind(this);
        this.onHandleDocumentClick = this.onHandleDocumentClick.bind(this);
        this.onHandleWindowResize = this.onHandleWindowResize.bind(this);
        this.debouncedOnHandleWindowsResize = this.debouncedOnHandleWindowsResize.bind(this);
        this.onHandleWindowScroll = this.onHandleWindowScroll.bind(this);
        this.debouncedOnHandleWindowScroll = this.debouncedOnHandleWindowScroll.bind(this);
        this.onHandleHamburgerKeydown = this.onHandleHamburgerKeydown.bind(this);
        this.onHandleDocumentKeydown = this.onHandleDocumentKeydown.bind(this);
        this.onHandleLastMenuItemKeydown = this.onHandleLastMenuItemKeydown.bind(this);
        this.onHandleMenuItemKeyDownLastFirstItem = this.onHandleMenuItemKeyDownLastFirstItem.bind(this);
        this.onHandleFirstMenuItemKeydown = this.onHandleFirstMenuItemKeydown.bind(this);
        this.onUnmountEventHandlers = this.onUnmountEventHandlers.bind(this);
        this.onHandleSubMenuItemFocus = this.onHandleSubMenuItemFocus.bind(this);
        this.adjustMoreMenuItems = this.adjustMoreMenuItems.bind(this);

        if (this.rootContainer === null) {
            return;
        }

        // Dropdown Menu
        addListener(this.rootContainer, 'mousedown', Selectors.MENU_BUTTON, this.onHandleMenuClick);
        addListener(this.rootContainer, 'keydown', Selectors.MENU_BUTTON, this.onHandleMenuClick);

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_BUTTON)).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('click', this.onHandleFocus);
            menuBtn.addEventListener('focus', this.onHandleFocus);
        });

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_LINK)).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('click', this.onHandleFocus);
            menuBtn.addEventListener('focus', this.onHandleFocus);
        });

        Array.from(
            this.qsa.call(this.rootContainer, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
        ).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('focus', this.onHandleSubMenuItemFocus);
        });

        this.leftMenu?.addEventListener('keydown', this.onHandleMenuItemKeyDownLastFirstItem);

        // Support Up/Down Arrow/Escape keys within submenu.
        Array.from(
            this.qsa.call(this.rootContainer, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
        ).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('keydown', this.onHandleMenuKeydown);
        });

        // Handle Escape for multi column menu
        Array.from(this.qsa.call(this.rootContainer, '.nested-submenu.nested-submenu--column ul > li > a')).forEach(
            (menuBtn: Element): void => {
                menuBtn.addEventListener('keydown', this.onHandleMenuKeydown);
            }
        );

        // Only bind hamburger events if hamburger element is on dom.
        // Set if "HEADER_SIMPLE" class to adjust the height of the header.
        if (this.hamburgerMenuButton) {
            this.hamburgerMenuButton.addEventListener('click', this.onHandleHamburgerMenuClick);
            this.hamburgerMenuButton.addEventListener('keydown', this.onHandleHamburgerKeydown);
        } else {
            this.rootContainer.classList.add(CssClassNames.HEADER_SIMPLE);
        }

        // Window & Document
        document.addEventListener('click', (ev: MouseEvent): void => {
            this.onHandleDocumentClick(ev);
        });
        document.addEventListener('keydown', this.onHandleDocumentKeydown);

        window.addEventListener('resize', (): void => {
            this.debouncedOnHandleWindowsResize();
        });

        if (this.rootContainer.dataset.stickyHeader !== null) {
            window.addEventListener('scroll', this.debouncedOnHandleWindowScroll);
        }

        PubSub.subscribe(PUB_SUB_EVENTS.FOCUS_MAIN_NAV_HAMBURGER, (): void => {
            this.hamburgerMenuButton.focus();
        });

        this.intializeMultiColumnMenu();

        if (this.useMeControl) {
            this.loadMeControl();
        }
    }

    loadMeControl(): void {
        const meControl: HTMLElement = document.getElementById('meControl');
        const signInSettingsJson: string = meControl && meControl.getAttribute('data-signinsettings');

        try {
            this.meControlOptions = JSON.parse(signInSettingsJson);
            this.initializeMeControl(this.meControlOptions);
        } catch (e) {
            /* tslint:disable no-console */
            console.error('MeControl Initialization failed');
            /* tslint:enable no-console */
        }
    }

    initializeMeControl(signInSettings: any): void {
        if (!signInSettings || Object.keys(signInSettings).length === 0) return;

        const fallbackTimeout: number = 1000;

        const w: any = window;

        if (w?.MeControl) {
            this.loadMe(w, signInSettings);
        } else {
            // If for any reason the call to https://controls.account.microsoft.com/me takes too long, revert to the simple HTML fallback
            // If that call ever returns, onMeControlReadyToLoad will get triggered and the MeControl will override the fallback HTML
            const timerId: NodeJS.Timeout = setTimeout(this.showMeControlFallback.bind(this), fallbackTimeout);

            w.onMeControlReadyToLoad = function (): void {
                // Clear fallback timer
                clearTimeout(timerId);

                // Load ME
                this.loadMe(w, signInSettings);
            };
        }
    }

    private loadMe(w: any, signInSettings: any): void {
        // Load MeControl
        try {
            w.MeControl.Loader.loadAsync(signInSettings).then(this.onHandleWindowResize);

            // Clear ready callback
            w.onMeControlReadyToLoad = null;
        } catch (e) {
            /* tslint:disable no-console */
            console.error('MeControl Initialization failed');
            /* tslint:enable no-console */
        }
    }

    private setMeControlMobileState(displayMode: string): void {
        if (!this.useMeControl) return;

        const w: any = window;
        if (w?.MeControl) {
            try {
                w.MeControl.API.setDisplayMode(displayMode);
            } catch (e) {
                /* tslint:disable no-console */
                console.error('MeControl setDisplayMode API call failed');
                /* tslint:enable no-console */
            }
        }
    }

    private showMeControlFallback(): void {
        const _options: any = this.meControlOptions;

        if (!_options) {
            return;
        }

        const authConfig: any = _options?.authProviderConfig;

        let authBtnTxt: string;

        let authLink: string;

        // Feel free to inject localized strings for Sign in and Sign out

        if (_options?.currentAccount?.authenticatedState === 'signedIn') {
            authBtnTxt = _options.signOutStr || 'Sign out';

            authLink = authConfig?.appSignOutUrl;
        } else {
            // IMPORTANT: For AAD signInURL may not be set, make sure to generate the approriate URL here
            authBtnTxt = _options.signInStr || 'Sign in';
            authLink = authConfig?.appSignInUrl;
        }

        // Simple HTML that shows either a sign in or a sign out link on the header container
        const noJsTemplate: string = `<div>
                <div class="msame_Header">
                    <a href="${authLink}" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 160px;outline-offset:inherit; display: inline-block; line-height: 54px; font-size: 100%; color: rgb(80,80,80); padding: 0 5px;">
                        ${authBtnTxt}
                    </a>
                </div>
            </div>`;

        const optionsContainer: HTMLElement = this.dq('#' + _options.containerId);
        // clear the container of any element
        while (optionsContainer.firstChild) {
            optionsContainer.removeChild(optionsContainer.firstChild);
        }
        optionsContainer.insertAdjacentHTML('beforeend', noJsTemplate);

        /* tslint:disable no-console */
        console.log('LoadFailed: Reverted to fallback');
        /* tslint:enable no-console */
    }

    private intializeMultiColumnMenu(): void {
        Array.from(this.qsa.call(this.rootContainer, '[data-multi-column]')).forEach(
            (element: HTMLUListElement): void => {
                if (element.getAttribute('data-multi-column')) {
                    const noOfColumns: number = parseInt(element.getAttribute('data-multi-column'), 10);
                    element?.classList.add('is-dropdown-submenu--multi-column');
                    element?.classList.add(`is-dropdown-submenu--multi-column-${noOfColumns}`);

                    childrenWithFilter(element, 'li').forEach((child: HTMLElement): void => {
                        child?.classList.add('nested-submenu', 'nested-submenu--column');
                    });
                }
            }
        );
    }

    private configureMultiColumnMenu(el: HTMLElement, isNestedWithinMore: boolean = true): void {
        Array.from(this.qsa.call(el, '[data-multi-column]')).forEach((element: HTMLUListElement): void => {
            if (element.getAttribute('data-multi-column')) {
                const noOfColumns: number = parseInt(element.getAttribute('data-multi-column'), 10);
                // If nested within More Menu, then display as a regular dropdown submenu.
                if (!isNestedWithinMore) {
                    element?.classList.add('is-dropdown-submenu--multi-column');
                    element?.classList.add(`is-dropdown-submenu--multi-column-${noOfColumns}`);

                    childrenWithFilter(element, 'li').forEach((child: HTMLElement): void => {
                        child?.classList.add('nested-submenu', 'nested-submenu--column');
                        childrenWithFilter(child, 'ul.bapi-menu.bapi-submenu').forEach(
                            (innerChild: HTMLElement): void => {
                                innerChild?.classList.remove('is-dropdown-submenu');
                            }
                        );
                    });
                } else {
                    element?.classList.remove('is-dropdown-submenu--multi-column');
                    element?.classList.remove(`is-dropdown-submenu--multi-column-${noOfColumns}`);

                    childrenWithFilter(element, 'li').forEach((child: HTMLElement): void => {
                        child?.classList.add('nested-submenu', 'nested-submenu--column');
                        childrenWithFilter(child, 'ul.bapi-menu.bapi-submenu').forEach(
                            (innerChild: HTMLElement): void => {
                                innerChild?.classList.add('is-dropdown-submenu');
                            }
                        );
                    });
                }
            }
        });
    }

    /**
     * Convert Multi Column Menu to accordion menu.
     * Called when moving from Desktop to Tablet/Mobile Viewport
     */
    private _convertMultiColumnMenuToAccordionMenu(): void {
        Array.from(this.qsa.call(this.rootContainer, '[data-multi-column]')).forEach(
            (element: HTMLUListElement): void => {
                if (element.getAttribute('data-multi-column')) {
                    const noOfColumns: number = parseInt(element.getAttribute('data-multi-column'), 10);

                    element?.classList.remove('is-dropdown-submenu--multi-column');
                    element?.classList.remove(`is-dropdown-submenu--multi-column-${noOfColumns}`);

                    childrenWithFilter(element, 'li.is-dropdown-submenu-parent').forEach((child: HTMLElement): void => {
                        child?.classList.remove('nested-submenu', 'nested-submenu--column');
                        child?.classList.add('is-accordion-submenu-parent', 'nested-submenu');
                        childrenWithFilter(child, 'ul.bapi-menu.bapi-submenu').forEach(
                            (innerChild: HTMLElement): void => {
                                innerChild?.classList.add('is-accordion-submenu', 'nested', 'bapi-hide');
                            }
                        );
                    });
                }
            }
        );
    }

    /**
     * Convert Accordion menu to Multi Column dropdown menu.
     * Called when moving from Mobile to Desktop Viewport.
     */
    private _convertAccordionMenuToMultiColumnMenu(): void {
        Array.from(this.qsa.call(this.rootContainer, '[data-multi-column]')).forEach(
            (element: HTMLUListElement): void => {
                if (element.getAttribute('data-multi-column')) {
                    const noOfColumns: number = parseInt(element.getAttribute('data-multi-column'), 10);

                    element?.classList.add('is-dropdown-submenu--multi-column');
                    element?.classList.add(`is-dropdown-submenu--multi-column-${noOfColumns}`);

                    childrenWithFilter(element, 'li.is-accordion-submenu-parent').forEach(
                        (child: HTMLElement): void => {
                            child?.classList.add('is-dropdown-submenu-parent');
                            child?.classList.add('nested-submenu');
                            child?.classList.add('nested-submenu--column');

                            child?.classList.remove('is-accordion-submenu-parent');
                            childrenWithFilter(child, 'ul.bapi-menu.bapi-submenu').forEach(
                                (innerChild: HTMLElement): void => {
                                    innerChild?.classList.remove(
                                        'is-dropdown-submenu',
                                        'is-accordion-submenu',
                                        'nested',
                                        'bapi-hide'
                                    );
                                }
                            );
                        }
                    );
                }
            }
        );
    }

    /**
     * Handles dropdown menu click
     * Opens the dropdown/accordion menu on click.
     * @param ev KeyboardEvent Event
     */
    onHandleMenuClick(ev: MouseEvent | KeyboardEvent): void {
        const menuToggleButton: EventTarget = ev.target;

        if (ev instanceof KeyboardEvent) {
            const keyCode: number = ev.keyCode ? ev.keyCode : ev.which;

            // Handle only Enter/Space within this method.
            if (ev.type === 'keydown' && keyCode !== keyCodeEnter && keyCode !== keyCodeSpace) return;
        }
        ev.stopPropagation();
        ev.preventDefault();

        const isDesktopViewport: MediaQueryList = window.matchMedia(`(min-width: ${Breakpoints.Desktop_Min}px)`);

        /**
         * Header uses dropdown menu for desktop resolution & accordion menu
         * for tablet/mobile resolution.
         *
         * Dropdown Menu classes - `is-dropdown-submenu-parent`, `is-dropdown-submenu`, `js-dropdown-active`
         * Accordion Menu classes - `is-accordion-submenu-parent`, `is-accordion-submenu`, `is-active`
         */
        if (isDesktopViewport.matches) {
            // Desktop resolution
            this.setDropdownMenuDirectionClass(menuToggleButton as HTMLElement);
            this._onHandleDropdownMenuClick(menuToggleButton as HTMLElement);
        } else {
            // Tablet, Mobile resolution
            this._onHandleAccordionMenuClick(menuToggleButton as HTMLElement);
        }

        (menuToggleButton as HTMLElement).focus();
    }

    /**
     * Adds the direction class for more-menu
     */
    setDropdownMenuDirectionClass(element: HTMLElement): void {
        let clientRect: DOMRect;
        let rootMenuElement: HTMLElement | Element;

        // Get the reference for top-most dropdown menu.
        if (element) {
            const menus: HTMLElement[] | Element[] = parentsUntil(
                element,
                '[data-menu-left]',
                Selectors.DROPDOWN_MENU_PARENT
            );
            rootMenuElement = menus && menus.length ? menus[menus.length - 1] : null;
            const rootMenuDropdownElement: HTMLElement | Element =
                childrenWithFilter(rootMenuElement, Selectors.MENU_BUTTON)[0] ?? null;

            if (rootMenuDropdownElement) {
                clientRect = rootMenuDropdownElement?.getBoundingClientRect();
            }
        }

        if (clientRect) {
            const { left, right }: DOMRect = clientRect;

            const isRTL: boolean = document && document.dir === 'rtl';

            const elementX: number = isRTL ? right : left;

            const shouldReverseDirection: boolean = isRTL
                ? elementX - SubMenuWidth * 2 - MinSpaceBeforeDirectionReverse < 0
                : elementX + SubMenuWidth * 2 + MinSpaceBeforeDirectionReverse > this.rootContainer.offsetWidth;

            if (shouldReverseDirection) {
                rootMenuElement?.classList.add('js-direction-reversed');
            } else {
                rootMenuElement?.classList.remove('js-direction-reversed');
            }
        }
    }

    onHandleSubMenuItemFocus(ev: FocusEvent): void {
        const currElm: EventTarget = ev.target;

        // Get all the open sub-menu's that are adjuscent to the current menu.
        const parent: HTMLElement = (currElm as HTMLElement).parentElement;
        const openMenus: HTMLElement[] | Element[] = siblings(
            parent,
            `${Selectors.DROPDOWN_MENU_PARENT}${Selectors.DROPDOWN_MENU_PARENT_OPEN}`
        );
        // Close then adjuscent sub-menu's after they have lost focus.
        if (openMenus && openMenus.length) {
            openMenus.forEach((openMenu: HTMLElement): void => {
                openMenu.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                const allOpenMenus: NodeListOf<HTMLElement> = this.qsa.call(
                    openMenu,
                    `${Selectors.DROPDOWN_MENU_SUBMENU}${Selectors.DROPDOWN_MENU_SUBMENU_OPEN}`
                );
                Array.from(allOpenMenus).forEach((selectedOpenMenu: HTMLElement): void => {
                    selectedOpenMenu.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);
                });
            });
            const button: HTMLButtonElement = this.qs.call(openMenus[0], `:scope > ${Selectors.MENU_BUTTON}`);
            this._adjustNestedMenuHeights(button, false);
        }
    }

    // Support Up/Down Arrow keys within submenu.
    onHandleMenuKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        // Handle keydown only for desktop viewport and not for accordion menu.
        if (this.IsLeftMenuAccordion && this.IsHamburgerMenuOpen) return;

        if (ev.keyCode === keyCodeArrowDown) {
            this._onHandleMenuKeydownArrowDownKey(ev);
        } else if (ev.keyCode === keyCodeArrowUp) {
            this._onHandleMenuKeydownArrowUpKey(ev);
        } else if (ev.keyCode === keyCodeEscape) {
            this._onHandleMenuKeydownEspaceKey(ev);
        }
    }

    onHandleMenuItemKeyDownLastFirstItem(ev: KeyboardEvent): void {
        const listOfVisibleChildren: Element[] | HTMLElement[] = (
            Array.from(this.qsa.call(this.leftMenu, 'li')) as any
        ).filter((child: HTMLElement): boolean => child.offsetParent !== null);

        const lengthOfVisibileChildren: number = listOfVisibleChildren.length;

        listOfVisibleChildren.forEach((child: HTMLElement, idx: number): void => {
            if ((child as Node).contains(ev.target as Node)) {
                // Hamburger menu uses absolute positioning.
                // On Shift + Tab, move the focus to hamburger menu button.
                if (idx === 0) {
                    this.onHandleFirstMenuItemKeydown(ev);
                }

                // Tab key on last menu element should close the sub-menu before moving
                // focus to right menu.

                if (idx === lengthOfVisibileChildren - 1) {
                    this.onHandleLastMenuItemKeydown(ev);
                }
            }
        });
    }

    /**
     * If this is last item on left menu [data-menu-left], then
     * Tab Key should Close the menu before moving tab focus to right menu.
     * @param ev Keyboard Event
     */
    onHandleLastMenuItemKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        if (ev.keyCode === keyCodeTab && !ev.shiftKey) {
            ev.stopPropagation();

            this._closeAllMenus();

            if (this.IsLeftMenuAccordion) {
                ev.preventDefault();
                this.hamburgerMenuButton.focus();
            }
        }
    }

    /**
     * Hamburger menu uses absolute positioning.
     * On Shift + Tab, Close the menu and shift tab focus to hamburger menu.
     * @param ev Keyboard Event
     */
    onHandleFirstMenuItemKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        if (ev.keyCode === keyCodeTab && ev.shiftKey && this.IsHamburgerMenuOpen) {
            this.hamburgerMenuButton.focus();
            ev.preventDefault();
            ev.stopPropagation();
        }
    }

    /**
     * Shift tab focus to next element within the sub-menu on Keyboard Arrow down keystoke.
     * @param ev - Keydown Event
     */
    _onHandleMenuKeydownArrowDownKey(ev: KeyboardEvent): void {
        if (!ev) return;

        const htmlTargetElement: HTMLAnchorElement | HTMLButtonElement = ev.target as
            | HTMLAnchorElement
            | HTMLButtonElement;

        // Don't handle Arrow Up / Arrow Down within multi column menu
        const isTargetWithinMultiColumnMenu: boolean =
            parentsUntil(htmlTargetElement, Selectors.MENU_LEFT, '.is-dropdown-submenu--multi-column').length > 0;

        if (isTargetWithinMultiColumnMenu) return;

        // Find all the focussable elements under the closes submenu.
        let focussableSubMenuElements: HTMLElement[] = [];
        if (!this.IsLeftMenuAccordion) {
            const closestFocussableSubMenuElements: HTMLElement = htmlTargetElement.closest(
                Selectors.DROPDOWN_MENU_SUBMENU
            );
            focussableSubMenuElements = this.qsa.call(
                closestFocussableSubMenuElements,
                ':scope  > li > a, :scope > li > button'
            );
        } else {
            const closestFocussableSubMenuElements: HTMLElement = htmlTargetElement.closest(
                Selectors.ACCORDION_MENU_SUBMENU
            );
            focussableSubMenuElements = this.qsa.call(
                closestFocussableSubMenuElements,
                ':scope > li > a, :scope > li > button'
            );
        }

        let elementIndex: number = -1;
        Array.from(focussableSubMenuElements).forEach(function (el: HTMLElement, index: number): void {
            if (el === htmlTargetElement) {
                elementIndex = index;
            }
        });

        // Cycle through the menu items and focus the next item on the list.
        // If this is the last item in sub-menu then move focus to first item.
        focussableSubMenuElements[(elementIndex + 1) % focussableSubMenuElements.length].focus();

        ev.stopPropagation();
        ev.preventDefault();
    }

    /**
     * Shift tab focus to previous item on Arrow Up Keystroke.
     * @param ev Keyboard Event
     */
    _onHandleMenuKeydownArrowUpKey(ev: KeyboardEvent): void {
        if (!ev) return;

        const htmlTargetElement: HTMLAnchorElement | HTMLButtonElement = ev.target as
            | HTMLAnchorElement
            | HTMLButtonElement;

        // Don't handle Arrow Up / Arrow Down within multi column menu
        const isTargetWithinMultiColumnMenu: boolean =
            parentsUntil(htmlTargetElement, Selectors.MENU_LEFT, '.is-dropdown-submenu--multi-column').length > 0;

        if (isTargetWithinMultiColumnMenu) return;

        // Find all the focussable elements under the closes submenu.
        let focussableSubMenuElements: HTMLElement[] = [];
        if (!this.IsLeftMenuAccordion) {
            const closestFocussableSubMenuElements: HTMLElement = htmlTargetElement.closest(
                Selectors.DROPDOWN_MENU_SUBMENU
            );
            focussableSubMenuElements = this.qsa.call(
                closestFocussableSubMenuElements,
                ':scope  > li > a, :scope > li > button'
            );
        } else {
            const closestFocussableSubMenuElements: HTMLElement = htmlTargetElement.closest(
                Selectors.ACCORDION_MENU_SUBMENU
            );
            focussableSubMenuElements = this.qsa.call(
                closestFocussableSubMenuElements,
                ':scope > li > a, :scope > li > button'
            );
        }

        let elementIndex: number = -1;
        Array.from(focussableSubMenuElements).forEach(function (el: HTMLElement, index: number): void {
            if (el === htmlTargetElement) {
                elementIndex = index;
            }
        });

        // If this is the first item in sub-menu, then move tab focus to last item.
        // Else, move tab focus to previos li element.
        if (elementIndex === 0) {
            focussableSubMenuElements[focussableSubMenuElements.length - 1].focus();
        } else {
            focussableSubMenuElements[elementIndex - 1].focus();
        }

        ev.stopPropagation();
        ev.preventDefault();
    }

    _onHandleMenuKeydownEspaceKey(ev: KeyboardEvent): void {
        if (!ev) return;

        ev.stopPropagation();

        const focussedElement: HTMLAnchorElement | HTMLButtonElement = ev.target as
            | HTMLAnchorElement
            | HTMLButtonElement;

        const parents: HTMLElement[] | Element[] = parentsUntil(
            focussedElement,
            Selectors.MENU_LEFT,
            Selectors.DROPDOWN_MENU_PARENT
        );

        if (!parents && parents.length) return;

        const topMostParentElement: HTMLElement | Element = parents[parents.length - 1];

        const menuToggleButton: HTMLElement =
            topMostParentElement && this.qs.call(topMostParentElement, `:scope > ${Selectors.MENU_BUTTON}`);

        if (menuToggleButton) {
            this._closeAllMenus();
            menuToggleButton.setAttribute('aria-expanded', 'false');
            menuToggleButton.focus();
        }
    }

    _onHandleMenuKeydownEspaceKeyMobile(ev: KeyboardEvent): void {
        if (!ev) return;

        ev.stopPropagation();

        const focussedElement: HTMLAnchorElement | HTMLButtonElement = ev.target as
            | HTMLAnchorElement
            | HTMLButtonElement;

        const parents: HTMLElement[] | Element[] = parentsUntil(
            focussedElement,
            '[data-menu-left]',
            Selectors.ACCORDION_MENU_PARENT
        );

        if (!parents && parents.length) return;

        const topMostParentElement: HTMLElement | Element = parents[parents.length - 1];

        const menuToggleButton: HTMLElement =
            topMostParentElement && this.qs.call(topMostParentElement, `:scope > ${Selectors.MENU_BUTTON}`);

        if (menuToggleButton) {
            // Tablet, Mobile resolution
            this._closeSubAccordionMenus(ev);
        }
    }

    onHandleHamburgerMenuClick(ev: MouseEvent): void {
        if (!ev.target) return;

        ev.stopPropagation();

        if (!this.hamburgerMenuButton && !this.leftMenu) return;

        this.hamburgerMenuButton?.classList.toggle(CssClassNames.HAMBURGER_MENU_OPEN);
        this.hamburgerMenuContainer?.classList.toggle(CssClassNames.HAMBURGER_CONTAINER_OPEN);

        this.IsHamburgerMenuOpen
            ? this.leftMenu?.classList.add(CssClassNames.ACCORDION_MENU_EXPANDED)
            : this.leftMenu?.classList.remove(CssClassNames.ACCORDION_MENU_EXPANDED);

        this.IsHamburgerMenuOpen
            ? this.hamburgerMenuButton.setAttribute('aria-expanded', 'true')
            : this.hamburgerMenuButton.setAttribute('aria-expanded', 'false');

        if (this.IsHamburgerMenuOpen) PubSub.publish(PUB_SUB_EVENTS.OPEN_HAMBURGER);
    }

    /**
     * Handle Key down event on hamburger menu.
     * @param ev KeyboardEvent
     */
    onHandleHamburgerKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        if (ev.keyCode === keyCodeTab && !ev.shiftKey && this.IsHamburgerMenuOpen) {
            ev.preventDefault();

            const visibileChildren: HTMLElement[] = (
                Array.from(this.qsa.call(this.leftMenu, "button, a,  [tabindex]:not([tabindex='-1'])")) as any
            ).filter((child: HTMLElement): boolean => child.offsetParent !== null);

            if (visibileChildren && visibileChildren.length) {
                visibileChildren[0].focus();
            }
        } else if (ev.shiftKey && ev.keyCode === keyCodeTab) {
            ev.preventDefault();

            if (this.meControl) {
                this.qs.call(this.meControl, Selectors.ME_CONTROL_SIGN_IN_TRIGGER).focus();
            } else {
                this.qs.call(this.brand, '[href]').focus();
            }

            if (this.IsHamburgerMenuOpen) {
                this._closeAllMenus();
            }
        } else if (ev.keyCode === keyCodeEscape) {
            if (this.IsHamburgerMenuOpen) {
                this._closeAllMenus();
            }
        }
    }

    onHandleFocus(ev: FocusEvent | KeyboardEvent): void {
        const focussedElement: EventTarget = ev.target;

        const level: number = this.getSubmenuLevel(focussedElement as HTMLElement);

        if (level === 3) return;

        this._closeOpenSubMenuAtSameLevel(ev);
    }

    /**
     * Catch all block for Document click event.
     *
     * Behavior -
     *  -   Close the hamburger/sub-menus if user clicks on non
     *      interactive menu elements.
     *
     *  -   Clicking on sub-menu won't fire this event as the captured event is
     *      not propagated up the chain.
     */
    onHandleDocumentClick(ev: MouseEvent): void {
        if (!ev) return;

        if ((ev.target as HTMLElement).matches('body')) return;

        const isFocusWithinHeaderContainer: boolean = this.rootContainer.contains(ev.target as Node);

        if (isFocusWithinHeaderContainer) return;

        this._closeAllMenus();
    }

    onHandleDocumentKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        const leftSubMenuLength: number = visibleChildren(this.leftMenu, 'li').length;
        let accordionMenuLength: number = 0;
        if (this.dq(`.${CssClassNames.ACCORDION_MENU}`)) {
            accordionMenuLength = visibleChildren(this.dq(`.${CssClassNames.ACCORDION_MENU}`)).length;
        }
        const isFirstLevelMenuItems: boolean = leftSubMenuLength === accordionMenuLength;

        const isDesktopViewport: MediaQueryList = window.matchMedia(`(min-width: ${Breakpoints.Desktop_Min}px)`);

        if (ev.keyCode === keyCodeEscape) {
            // Mobile Resolution
            if (!isDesktopViewport.matches) {
                this._onHandleMenuKeydownEspaceKeyMobile(ev);

                // Set focus to hamburger menu on escape key only if current focus is within first level accordion menu
                if (isFirstLevelMenuItems && this.IsHamburgerMenuOpen) {
                    this.hamburgerMenuButton?.click();
                    this.hamburgerMenuButton?.focus();
                }
            }
            // Desktop resolution
            else {
                this._closeAllMenus();
            }
        }
    }

    /**
     * Enable sticky behaviour if [data-sticky-header] is set for <header> element.
     */
    private onHandleWindowScroll(): void {
        const scrollTop: number = window.pageYOffset ?? 0;

        const isDesktopViewport: MediaQueryList = window.matchMedia(`(min-width: ${Breakpoints.Desktop_Min}px)`);

        // Disable sticky if viewport height is less than 720px.
        // This causes issues when users are zoom settings on between 200-400%
        const isViewportHeightSmall: MediaQueryList = window.matchMedia(`(max-height: 720px)`);

        if (this.thisSubNav) {
            if (scrollTop > HeaderHeight * 2 && isDesktopViewport.matches) {
                PubSub.publish(PUB_SUB_EVENTS.ENABLE_SUBNAV_STICKY);
            } else {
                PubSub.publish(PUB_SUB_EVENTS.DISABLE_SUBNAV_STICKY);
            }
            this.toggleStickyNavigation(false);
            return;
        }

        // Disable sticky header for mobile viewport & if viewport height falls below 720px
        if (!isDesktopViewport.matches || isViewportHeightSmall.matches) {
            this.toggleStickyNavigation(false);
            return;
        }

        // If we got here, then page has no sub-navigation and we are on desktop viewport.
        this.toggleStickyNavigation(scrollTop > HeaderHeight * 2);
    }

    private toggleStickyNavigation(enable: boolean): void {
        if (enable) {
            this.rootContainer.style.display = 'none';
            this.rootContainer.classList.add(CssClassNames.HEADER_STICKY);
            this.rootContainer.style.display = 'block';
        } else {
            this.rootContainer?.classList.remove(CssClassNames.HEADER_STICKY);
        }
    }

    // Creating a reference for the throttled function which can be used to de-register event handler during unmount.
    private debouncedOnHandleWindowScroll: () => void = debounce(this.onHandleWindowScroll, 100);

    onHandleWindowResize(): void {
        const isDesktopViewport: MediaQueryList = window.matchMedia(`(min-width: ${Breakpoints.Desktop_Min}px)`);

        // const isMoreMenuDirectionFlipped = window.matchMedia

        // Mobile resolution
        if (!isDesktopViewport.matches) {
            // Close all menus when moving from desktop to mobile resolution
            if (!this.IsLeftMenuAccordion) {
                this._closeAllMenus();
                this._convertMultiColumnMenuToAccordionMenu();
                this.setMeControlMobileState(RuntimeDisplayMode.Compressed);
            }

            this._resetMoreMenu();

            if (this.hasMultiColumnMenu) {
                this.adjustMultiColumnWidth(true);
            }

            this.leftMenu?.classList.remove(CssClassNames.DROPDOWN_MENU);
            this.leftMenu?.classList.add(CssClassNames.ACCORDION_MENU);

            // Switch from dropdown menu to accordion menu
            if (this.leftMenu) {
                Array.from(this.qsa.call(this.leftMenu, '.is-dropdown-submenu-parent')).forEach(
                    (innerEL: HTMLElement): void => {
                        innerEL?.classList.remove('is-dropdown-submenu-parent');
                        innerEL?.classList.add('is-accordion-submenu-parent');
                    }
                );
            }

            if (this.leftMenu) {
                Array.from(this.qsa.call(this.leftMenu, '.is-dropdown-submenu')).forEach(
                    (innerEL: HTMLElement): void => {
                        innerEL?.classList.remove('is-dropdown-submenu');
                        innerEL?.classList.add('is-accordion-submenu');
                        innerEL?.classList.add('nested');
                        innerEL?.classList.add('bapi-hide');
                    }
                );
            }
        }
        // Desktop resolution
        else {
            // Close all menus when moving from mobile to desktop resolution
            if (this.IsLeftMenuAccordion) {
                this._closeAllMenus();
                this._convertAccordionMenuToMultiColumnMenu();
                if (this.useMeControl && this.meControl) {
                    this.loadMeControl();
                }
                this.setMeControlMobileState(RuntimeDisplayMode.Standard);
            }

            this.leftMenu?.classList.remove(CssClassNames.ACCORDION_MENU);
            this.leftMenu?.classList.add(CssClassNames.DROPDOWN_MENU);

            // Switch from accordion menu to dropdown menu
            if (this.leftMenu) {
                Array.from(this.qsa.call(this.leftMenu, '.is-accordion-submenu-parent')).forEach(
                    (innerEL: HTMLElement): void => {
                        innerEL?.classList.remove('is-accordion-submenu-parent');
                        innerEL?.classList.add('is-dropdown-submenu-parent');
                    }
                );
            }

            if (this.leftMenu) {
                Array.from(this.qsa.call(this.leftMenu, '.is-accordion-submenu.nested')).forEach(
                    (innerEL: HTMLElement): void => {
                        innerEL?.classList.remove('is-accordion-submenu', 'bapi-hide', 'nested');
                        innerEL?.classList.add('is-dropdown-submenu');
                    }
                );
            }
            this.leftMenu?.classList.remove('bapi-accordion-menu');
            this.leftMenu?.classList.add('bapi-dropdown');

            // Calculate and adjust more menu only if the more menu element is available on DOM.
            if (this.moreMenu) {
                this.adjustMoreMenuItems();
            }

            if (this.hasMultiColumnMenu) {
                this.adjustMultiColumnWidth();
            }
        }
    }

    private debouncedOnHandleWindowsResize: () => void = debounce(this.onHandleWindowResize, 100, { leading: true });

    private adjustMoreMenuItems(reset: boolean = false): void {
        //  calculateElementWidths() function is recursive.
        //  Set IsMoreMenuAdjustmentsInProgress to true to prevent function from calling again while adjustments are in progress.
        if (!this.IsMoreMenuAdjustmentsInProgress) {
            this.IsMoreMenuAdjustmentsInProgress = true;
            this.calculateElementWidths();
            this.IsMoreMenuAdjustmentsInProgress = false;
        }
    }

    /**
     * Resets & Removes all the `More` menu li elements back to regular menu.
     */
    private _resetMoreMenu(): void {
        if (this.IsMoreMenuAdjustmentsInProgress || this.IsMoreMenuEmpty) return;

        this.IsMoreMenuAdjustmentsInProgress = true;

        let loopKillSwitch: number = 0;

        while (!this.IsMoreMenuEmpty) {
            const firstMoreMenuElement: HTMLElement = this.qs.call(this.moreMenuList, ':scope > li');

            const parentNode: Node = this.moreMenu.parentNode;
            if (parentNode) {
                parentNode.insertBefore(firstMoreMenuElement, this.moreMenu);
            }
            firstMoreMenuElement.removeAttribute('data-width');

            loopKillSwitch++;

            // If this loop runs over 200 times, kill it to prevent UI from becoming unresponsive.
            if (loopKillSwitch > 200) break;
        }

        if (this.IsMoreMenuEmpty) {
            this.moreMenu.style.display = 'none';
        } else {
            this.moreMenu.style.display = 'block';
        }
        this.IsMoreMenuAdjustmentsInProgress = false;
    }

    /**
     * Returns total width of all menu elements and total Nav Width
     */
    private calculateElementWidths(): void {
        const [requiredSpace, navWidth]: [number, number] = this.calculateAvailableMenuSpace();

        // If body / Enclosing div is set to display: none, then widths returned will be 0.
        // Gaurd against 0 widths.
        if (requiredSpace === 0 || navWidth === 0) return;

        // If required space is less than available space then shrink to More Menu
        if (requiredSpace + MoreMenuMinSpace > navWidth) {
            // Move last li item to more menu
            const notMore: NodeListOf<HTMLElement> = this.qsa.call(this.leftMenu, ':scope > li:not(.more)');
            const lastItem: any = notMore[notMore.length - 1];
            const widthOfLastItem: number = this.getWidthWithMargins(lastItem);
            lastItem?.setAttribute('data-width', widthOfLastItem);
            lastItem?.classList.add(CssClassNames.DROPDOWN_MENU_MORE_MENU_NESTED);
            this.configureMultiColumnMenu(lastItem);
            this.moreMenuList.prepend(lastItem);

            if (this.IsMoreMenuEmpty) {
                this.moreMenu.style.display = 'none';
            } else {
                this.moreMenu.style.display = 'block';
            }

            this.calculateElementWidths();
        } else {
            if (this.IsMoreMenuEmpty) return;
            // Move last li item outside more menu to main menu.
            const firstMoreMenuElement: HTMLElement = this.qs.call(this.moreMenuList, ':scope > li');

            let requiredWidth: number =
                requiredSpace + (Number(firstMoreMenuElement.dataset.width) ?? 0) + MoreMenuMinSpace;

            // if this is the last item within more menu, then substract width of more menu from required width
            if (this.moreMenuItemCount === 1) {
                requiredWidth = requiredWidth - this.moreMenuButton?.offsetWidth;
            }

            if (requiredWidth < navWidth) {
                this.configureMultiColumnMenu(firstMoreMenuElement, false);

                const parentNode: Node = this.moreMenu.parentNode;
                if (parentNode) {
                    parentNode.insertBefore(firstMoreMenuElement, this.moreMenu);
                }
                firstMoreMenuElement.removeAttribute('data-width');
                firstMoreMenuElement?.classList.remove(CssClassNames.DROPDOWN_MENU_MORE_MENU_NESTED);

                if (this.IsMoreMenuEmpty) {
                    this.moreMenu.style.display = 'none';
                } else {
                    this.moreMenu.style.display = 'block';
                }
                this.calculateElementWidths();
            }
        }
    }

    private calculateAvailableMenuSpace(): [number, number] {
        const logoWidth: number = this.getWidthWithMargins(this.logoContainer);
        const brandNameWidth: number = this.getWidthWithMargins(this.brand);
        const separatorWidth: number = this.getWidthWithMargins(this.separator);

        const navWidth: number = this.topbarContainer.offsetWidth ?? 0;

        const rightMenuWidth: number = this.getWidthWithMargins(this.rightMenu);

        const leftMenuItems: HTMLElement[] = this.qsa.call(this.leftMenu, ':scope > li:not(.more)');

        let leftMenuWidth: number = 0;

        Array.from(leftMenuItems).forEach((element: HTMLElement): void => {
            leftMenuWidth += this.getWidthWithMargins(element);
        });

        let requiredSpace: number = logoWidth + brandNameWidth + separatorWidth + rightMenuWidth + leftMenuWidth;

        if (this.moreMenu && this.moreMenu.offsetWidth > 0 && this.moreMenu.style.display !== 'none') {
            requiredSpace += this.getWidthWithMargins(this.moreMenu);
        }

        return [requiredSpace, navWidth];
    }

    private getWidthWithMargins(el: HTMLElement): number {
        if (!el) {
            return 0;
        }
        const elWidth: number = el.offsetWidth;
        const elStyle: CSSStyleDeclaration = window.getComputedStyle(el);
        const elMarginLeft: number = parseInt(elStyle.marginLeft, 10);
        const elMarginRight: number = parseInt(elStyle.marginRight, 10);
        const elOuterWidth: number = elWidth + elMarginLeft + elMarginRight;
        return elOuterWidth;
    }

    private adjustMultiColumnWidth(resetStyles: boolean = false): void {
        Array.from(this.qsa.call(this.rootContainer, "[data-multi-column='5']")).forEach(
            (element: HTMLUListElement): void => {
                const isRTL: boolean = document && document.dir === 'rtl';
                if (element && !element.dataset.multiColumn) return;

                if (resetStyles) {
                    element.removeAttribute('style');
                    return;
                }
                const totalWidthMinusTopBar: number = window.innerWidth - this.topbarContainer.offsetWidth;

                const topBarX: number = totalWidthMinusTopBar / 2;

                // Left/Right Edges for dropdown menu.
                const { left, right }: { left: number; right: number } = element.getBoundingClientRect();

                // For RTL, use Right edge.
                let dropdownX: number = isRTL ? right : left;

                // Fallback for IE 11
                if (typeof dropdownX === 'undefined') {
                    const clientRect: any = element.getClientRects();
                    dropdownX = isRTL ? clientRect[0]?.right : clientRect[0]?.left;
                }

                let dropdownMargin: string = '';
                const computedStyle: CSSStyleDeclaration = window.getComputedStyle(element);

                if (isRTL) {
                    dropdownMargin = computedStyle.marginRight.replace('px', '');
                } else {
                    dropdownMargin = computedStyle.marginLeft.replace('px', '');
                }

                const margin: number = isRTL
                    ? topBarX + parseInt(dropdownMargin, 10) - (window.innerWidth - dropdownX)
                    : topBarX + parseInt(dropdownMargin, 10) - dropdownX;

                if (isRTL) {
                    element.style.marginRight = margin + 'px';
                    element.style.width = this.topbarContainer.offsetWidth + 'px';
                } else {
                    element.style.marginLeft = margin + 'px';
                    element.style.width = this.topbarContainer.offsetWidth + 'px';
                }
            }
        );
    }

    /**
     *  Opens/Closes the the dropdown menu
     * @param currElm reference to the dropdown button element.
     */
    private _onHandleDropdownMenuClick(currElm: HTMLElement): void {
        const isMenuExpanded: boolean = currElm.parentElement?.classList.contains(
            CssClassNames.DROPDOWN_MENU_PARENT_OPEN
        );

        if (!isMenuExpanded) {
            currElm.parentElement?.classList.add(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((sibling: HTMLElement): void => {
                sibling?.classList.add(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);
                if (sibling?.classList.contains('is-dropdown-submenu--multi-column')) {
                    this.adjustMultiColumnWidth();
                }

                /**
                 *  Update attributes for a11y in case this is a multi column menu
                 */
                const buttons: NodeListOf<HTMLButtonElement> = this.qsa.call(sibling, Selectors.MENU_BUTTON);
                Array.from(buttons).forEach((btn: HTMLButtonElement): void => {
                    const uuid: string = `ul-${uuidv4()}`;
                    btn.setAttribute('tabindex', '-1');
                    btn.setAttribute('id', uuid);
                    siblings(btn, 'ul').forEach((sib: HTMLElement): void => {
                        sib.setAttribute('aria-labelledby', uuid);
                    });
                });
            });
        } else {
            currElm.parentElement?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void =>
                el?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN)
            );
            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((sibling: HTMLElement): void => {
                if (sibling.classList.contains('is-dropdown-submenu--multi-column')) {
                    this.adjustMultiColumnWidth(true);
                    const buttons: NodeListOf<HTMLButtonElement> = this.qsa.call(sibling, Selectors.MENU_BUTTON);
                    Array.from(buttons).forEach((btn: HTMLButtonElement): void => {
                        const uuid: string = `ul-${uuidv4()}`;
                        btn.removeAttribute('tabindex');
                        btn.removeAttribute('id');
                        siblings(btn, 'ul').forEach((sib: HTMLElement): void => {
                            sib.removeAttribute('aria-labelledby');
                        });
                    });
                }
            });

            // Close all nested menus under this menu.
            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void => {
                Array.from(
                    this.qsa.call(el, `${Selectors.DROPDOWN_MENU_SUBMENU}${Selectors.DROPDOWN_MENU_SUBMENU_OPEN}`)
                ).forEach((innerEL: HTMLElement): void => {
                    innerEL?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);
                });
            });

            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void => {
                Array.from(
                    this.qsa.call(el, `${Selectors.DROPDOWN_MENU_PARENT}${Selectors.DROPDOWN_MENU_PARENT_OPEN}`)
                ).forEach((innerEL: HTMLElement): void => {
                    innerEL?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                });
            });

            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void => {
                Array.from(this.qsa.call(el, `${Selectors.MENU_BUTTON}`)).forEach((innerEL: HTMLElement): void => {
                    innerEL.setAttribute('aria-expanded', 'false');
                });
            });
        }

        currElm.setAttribute('aria-expanded', isMenuExpanded ? 'false' : 'true');

        this._adjustNestedMenuHeights(currElm, false);
    }

    /**
     * Adjust heights of flyout menus.
     * @param currElm
     * @param isMenuExpanded true if menu is in expanded state.
     */
    private _adjustNestedMenuHeights(currElm: HTMLElement, isStabilizationCycle: boolean): void {
        const level: number = this.getSubmenuLevel(currElm);
        const isCollapsableMenu: boolean = level === 3;

        // If this is second level/third level menu, then height of menu should be equal to first level menu.
        if (level < 2) return;

        // Reset Heights to original size.
        if (!isStabilizationCycle) {
            if (this.qs.call(this.leftMenu, '[data-height-adjusted]')) {
                this.qs.call(this.leftMenu, '[data-height-adjusted]').style.height = 'auto';
            }
            this._adjustNestedMenuHeights(currElm, true);
            return;
        }

        let parentMenu: HTMLElement | Element;

        const parents: HTMLElement[] | Element[] = parentsUntil(
            currElm,
            '[data-menu-left]',
            Selectors.DROPDOWN_MENU_PARENT
        );
        const isMultiColumnMenu: boolean = parentsUntil(currElm, Selectors.MENU_LEFT, '[data-multi-column]').length > 0;

        /**
         * For level 2 menu - We need to adjust the height of dropdown menu 1 level up
         * For level 3 menu - We need to adjust the height of dropdown menu 2 level's up
         */
        if (level === 2 && parents && parents.length === 2) {
            const parentElement: HTMLElement | Element = parents[1];
            parentMenu = parentElement ?? null;
        } else if (level === 3 && parents && parents.length === 3) {
            const parentElement: HTMLElement | Element = parents[2];
            parentMenu = parentElement ?? null;
        }

        if (!parentMenu) return;

        const heightOfParentMenu: number = (
            childrenWithFilter(parentMenu, Selectors.DROPDOWN_MENU_SUBMENU)[0] as HTMLElement
        ).offsetHeight;

        // If this is a collapsable menu then find the height on closest submenu (scan up) otherwise find height of sibling (scan sideways)
        const heightOfNestedMenu: number = isCollapsableMenu
            ? currElm.closest(Selectors.DROPDOWN_MENU_SUBMENU).offsetHeight
            : (siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU)[0] as HTMLElement).offsetHeight;

        if (heightOfNestedMenu < heightOfParentMenu) {
            // Set height of Nested Menu
            if (isCollapsableMenu && isMultiColumnMenu) {
                const closestMultiParent: HTMLElement = currElm.closest('.is-dropdown-submenu');
                closestMultiParent.style.height = heightOfParentMenu + 'px';
                closestMultiParent.setAttribute('data-height-adjusted', 'true');
            } else if (isCollapsableMenu) {
                const childs: Element[] | HTMLElement[] = childrenWithFilter(
                    parentMenu,
                    Selectors.DROPDOWN_MENU_SUBMENU
                );
                childs.forEach((child: HTMLElement): void => {
                    child.style.height = heightOfParentMenu.toString() + 'px';
                    child.setAttribute('data-height-adjusted', 'true');
                });
            } else {
                siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((child: HTMLElement): void => {
                    child.style.height = heightOfParentMenu.toString() + 'px';
                    child.setAttribute('data-height-adjusted', 'true');
                });
            }
        } else {
            const childs: Element[] | HTMLElement[] = childrenWithFilter(parentMenu, Selectors.DROPDOWN_MENU_SUBMENU);
            childs.forEach((child: HTMLElement): void => {
                child.style.height = heightOfNestedMenu.toString() + 'px';
                child.setAttribute('data-height-adjusted', 'true');
            });
        }
    }

    private _onHandleAccordionMenuClick(currElm: HTMLElement): void {
        const isMenuExpanded: boolean = currElm
            .closest(Selectors.ACCORDION_MENU_PARENT)
            ?.classList.contains(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

        if (isMenuExpanded) {
            currElm
                .closest(Selectors.ACCORDION_MENU_PARENT)
                ?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);
        } else {
            currElm.closest(Selectors.ACCORDION_MENU_PARENT)?.classList.add(CssClassNames.ACCORDION_MENU_PARENT_OPEN);
        }

        if (isMenuExpanded) {
            siblings(currElm, Selectors.ACCORDION_MENU_SUBMENU).forEach((el: Element): void => {
                el?.classList.remove(CssClassNames.ACCORDION_MENU_SUBMENU_OPEN);
                el?.classList.add(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);
            });
        } else {
            siblings(currElm, Selectors.ACCORDION_MENU_SUBMENU).forEach((el: Element): void => {
                el?.classList.add(CssClassNames.ACCORDION_MENU_SUBMENU_OPEN);
                el?.classList.remove(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);
            });
        }

        currElm.setAttribute('aria-expanded', isMenuExpanded ? 'false' : 'true');

        this.qs
            .call(this.leftMenu, `${Selectors.ACCORDION_MENU_PARENT}:not(${Selectors.ACCORDION_MENU_PARENT_OPEN})`)
            ?.classList.remove('has-accordion-submenu-active');

        const parents: HTMLElement[] | Element[] = parentsUntil(
            currElm,
            '[data-menu-left]',
            '.is-accordion-submenu-parent'
        );
        const isNestedAccordionMenu: boolean = parents.length > 1;

        if (parents && isNestedAccordionMenu) {
            const parent: HTMLElement | Element = parents[parents.length - 1];
            parent?.classList.add('has-accordion-submenu-active');
        }
    }

    private _closeOpenSubMenuAtSameLevel(ev: MouseEvent | KeyboardEvent | FocusEvent): void {
        const currElm: HTMLElement = ev.target as HTMLElement;

        // Dropdown menu's at same level.
        const menusAtSameLevel: HTMLElement[] | Element[] = siblings(
            currElm.parentElement,
            '.is-dropdown-submenu-parent'
        );

        if (menusAtSameLevel && menusAtSameLevel.length) {
            menusAtSameLevel.forEach((element: Element, _index: number): void => {
                element.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                this.qs
                    .call(element, Selectors.DROPDOWN_MENU_SUBMENU)
                    ?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);
                this.qs
                    .call(element, Selectors.DROPDOWN_MENU_PARENT_OPEN)
                    ?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                this.qs.call(element, Selectors.MENU_BUTTON)?.setAttribute('aria-expanded', 'false');
                if (this.qs.call(element, '[data-height-adjusted]')) {
                    this.qs.call(element, '[data-height-adjusted]').style.height = 'auto';
                }

                const btns: NodeListOf<HTMLButtonElement> = this.qsa.call(element, "[tabindex='-1']");
                if (btns?.length) {
                    Array.from(btns).forEach((btn: HTMLButtonElement): void => {
                        btn.removeAttribute('tabindex');
                        if (btn.matches('[id]')) {
                            btn.removeAttribute('id');
                            siblings(btn, 'ul[aria-labelledBy]').forEach((el: Element): void => {
                                el?.removeAttribute('aria-labelledBy');
                            });
                        }
                    });
                }
            });
        }

        // Accordion menu's at same level.
        const accordionsAtSameLevel: HTMLElement[] | Element[] = siblings(
            currElm.parentElement,
            '.is-accordion-submenu-parent, .is-menu-link'
        );

        if (accordionsAtSameLevel && accordionsAtSameLevel.length) {
            accordionsAtSameLevel.forEach((element: Element, _index: number): void => {
                element.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

                // Close all sub-menus under this menu.
                Array.from(this.qsa.call(element, Selectors.ACCORDION_MENU_PARENT)).forEach((el: HTMLElement): void => {
                    el?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);
                });
                Array.from(this.qsa.call(element, Selectors.ACCORDION_MENU_SUBMENU)).forEach(
                    (el: HTMLElement): void => {
                        el?.classList.remove(CssClassNames.ACCORDION_MENU_SUBMENU_OPEN);
                        el?.classList.add(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);
                    }
                );
                Array.from(this.qsa.call(element, Selectors.MENU_BUTTON)).forEach((el: HTMLElement): void => {
                    el?.setAttribute('aria-expanded', 'false');
                });
            });
        }
    }

    /**
     * Closes all the open accordion and dropdown menu.
     * By default the hamburger menu is closed if open.
     */
    private _closeAllMenus(): void {
        // Dropdown Menu
        this.qs
            .call(this.rootContainer, `${Selectors.DROPDOWN_MENU_PARENT}${Selectors.DROPDOWN_MENU_PARENT_OPEN}`)
            ?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);

        this.qs
            .call(this.rootContainer, `${Selectors.DROPDOWN_MENU_SUBMENU}${Selectors.DROPDOWN_MENU_SUBMENU_OPEN}`)
            ?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);

        // Accordion Menus
        this.qs
            .call(this.rootContainer, `${Selectors.ACCORDION_MENU_PARENT}${Selectors.ACCORDION_MENU_PARENT_OPEN}`)
            ?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

        this.qs
            .call(this.rootContainer, `${Selectors.ACCORDION_MENU_SUBMENU}${Selectors.ACCORDION_MENU_SUBMENU_OPEN}`)
            ?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

        this.qs
            .call(this.rootContainer, `${Selectors.ACCORDION_MENU_SUBMENU}${Selectors.ACCORDION_MENU_SUBMENU_OPEN}`)
            ?.classList.add(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);

        this.qs.call(this.rootContainer, '[data-height-adjusted]')?.removeAttribute('data-height-adjusted');
        if (this.qs.call(this.rootContainer, '[data-height-adjusted]')) {
            this.qs.call(this.rootContainer, '[data-height-adjusted]').style.height = 'auto';
        }

        // bug fix - https://dev.azure.com/dynamicscrm/CXP/_workitems/edit/1852689
        this.qs.call(this.rootContainer, Selectors.MENU_BUTTON)?.setAttribute('aria-expanded', 'false');

        this.clearA11yAttributesForMultiColumnMenu();

        /**
         * If hamburger menu is open, then close it.`
         */
        if (this.IsHamburgerMenuOpen) {
            this.hamburgerMenuButton?.click();
        }
    }

    /**
     * Closes Submenu one by one accordion menu.
     * By default the hamburger menu is closed if open.
     */
    private _closeSubAccordionMenus(ev: KeyboardEvent): void {
        const target: HTMLElement = ev.target as HTMLElement;
        if (!target) return;

        const parents: HTMLElement[] | Element[] = parentsUntil(
            target,
            '[data-menu-left]',
            `${Selectors.ACCORDION_MENU_PARENT}${Selectors.ACCORDION_MENU_PARENT_OPEN}`
        );

        if (parent.length === 0) return;

        const firstParent: HTMLElement | Element = parents[0];

        firstParent?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

        this.qs
            .call(firstParent, `${Selectors.ACCORDION_MENU_SUBMENU}${Selectors.ACCORDION_MENU_SUBMENU_OPEN}`)
            ?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);
        this.qs
            .call(firstParent, `${Selectors.ACCORDION_MENU_SUBMENU}${Selectors.ACCORDION_MENU_SUBMENU_OPEN}`)
            ?.classList.add(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);
        this.qs.call(firstParent, Selectors.MENU_BUTTON).setAttribute('aria-expanded', 'false');
        this.qs.call(firstParent, Selectors.MENU_BUTTON).focus();
    }

    private clearA11yAttributesForMultiColumnMenu(): void {
        const btns: NodeListOf<HTMLButtonElement> = this.qsa.call(this.rootContainer, "[tabindex='-1']");
        if (btns?.length) {
            Array.from(btns).forEach((btn: HTMLButtonElement): void => {
                btn.removeAttribute('tabindex');
                if (btn.matches('[id]')) {
                    btn.removeAttribute('id');
                    siblings(btn, 'ul[aria-labelledBy]').forEach((el: Element): void => {
                        el?.removeAttribute('aria-labelledBy');
                    });
                }
            });
        }
    }

    /**
     * Gets the dropdown nesting level
     * @param element dropdown menu toggle button
     * Can be 1, 2, or 3
     */
    private getSubmenuLevel(element: HTMLElement): number {
        return parentsUntil(element, '[data-menu-left]', Selectors.DROPDOWN_MENU_PARENT).length;
    }

    /**
     * Returns true if hamburger manu is open.
     */
    get IsHamburgerMenuOpen(): boolean {
        return (
            this.hamburgerMenuButton && this.hamburgerMenuButton.classList.contains(CssClassNames.HAMBURGER_MENU_OPEN)
        );
    }

    /**
     * Returns true if left menu is rendered as a accordion menu.
     */
    get IsLeftMenuAccordion(): boolean {
        return this.leftMenu?.classList.contains(CssClassNames.ACCORDION_MENU);
    }

    /**
     * Returns true if more menu has elements.
     */
    get IsMoreMenuEmpty(): boolean {
        let hasVisibleChildren: boolean = false;
        if (this.moreMenuList && this.moreMenuList.children) {
            // Some of the links are hidden on desktop but visible on mobile.

            // IsMoreMenuEmpty return true for those links,
            Array.from(this.moreMenuList.children).forEach((element: HTMLElement): void => {
                if (element.style.display !== 'none') {
                    hasVisibleChildren = true;
                }
            });

            return !hasVisibleChildren;
        }

        return true;
    }

    get moreMenuItemCount(): number {
        let count: number = 0;
        if (this.moreMenuList && this.moreMenuList.children.length) {
            // Some of the links are hidden on desktop but visible on mobile.

            // IsMoreMenuEmpty return true for those links,
            Array.from(this.moreMenuList.children).forEach((element: HTMLElement): void => {
                if (element.style.display !== 'none') {
                    count++;
                }
            });
        }

        return count;
    }

    get useMeControl(): boolean {
        return this.rootContainer.dataset.meControl && Boolean(this.rootContainer.dataset.meControl) === true;
    }

    /**
     * Unmount/un-register all the event handlers here.
     */
    onUnmountEventHandlers(): void {
        removeListener(this.rootContainer, 'mousedown', `${Selectors.MENU_BUTTON}`, this.onHandleMenuClick);
        removeListener(this.rootContainer, 'keydown', `${Selectors.MENU_BUTTON}`, this.onHandleMenuClick);

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_BUTTON)).forEach((menuBtn: Element): void => {
            menuBtn.removeEventListener('click', this.onHandleFocus);
            menuBtn.removeEventListener('focus', this.onHandleFocus);
        });

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_LINK)).forEach((menuBtn: Element): void => {
            menuBtn.removeEventListener('click', this.onHandleFocus);
            menuBtn.removeEventListener('focus', this.onHandleFocus);
        });

        Array.from(
            this.qsa.call(this.rootContainer, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
        ).forEach((menuBtn: Element): void => {
            menuBtn.removeEventListener('focus', this.onHandleSubMenuItemFocus);
        });

        this.leftMenu?.removeEventListener('keydown', this.onHandleMenuItemKeyDownLastFirstItem);

        if (this.leftMenu) {
            Array.from(
                this.qsa.call(this.leftMenu, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
            ).forEach((menuBtn: Element): void => {
                menuBtn.removeEventListener('keydown', this.onHandleMenuKeydown);
            });
        }

        if (this.hamburgerMenuButton) {
            this.hamburgerMenuButton.removeEventListener('click', this.onHandleHamburgerMenuClick);
            this.hamburgerMenuButton.removeEventListener('keydown', this.onHandleHamburgerKeydown);
        }

        // window and document
        document.removeEventListener('click', this.onHandleDocumentClick);
        document.removeEventListener('keydown', this.onHandleDocumentKeydown);

        window.removeEventListener('resize', this.debouncedOnHandleWindowsResize);

        if (this.rootContainer.dataset.stickyHeader !== null) {
            window.removeEventListener('scroll', this.debouncedOnHandleWindowScroll);
        }

        PubSub.unsubscibeAll();
    }
}

const bapiHeader: BapiHeader = new BapiHeader();
(window as any).BapiHeader = bapiHeader;
bapiHeader.load();
