import { Breakpoints, CssClassNames, Selectors } from './constants';
import {
    addListener,
    debounce,
    keyCodeArrowDown,
    keyCodeArrowUp,
    keyCodeEnter,
    keyCodeEscape,
    keyCodeSpace,
    keyCodeTab,
    parentsUntil,
    removeListener,
    siblings,
} from '@bapiweb-ux/web-utilities';

// Polyfill for Array.from for IE
import 'core-js/features/array/from';
import { PubSub, PUB_SUB_EVENTS } from './pubsub';

export class SubNavigation {
    // selector funtions
    dq: (selector: string) => HTMLElement = document.querySelector.bind(document);
    qs: (selector: string) => HTMLElement = Element.prototype.querySelector;
    qsa: (selector: string) => NodeListOf<HTMLElement> = Element.prototype.querySelectorAll;
    // elements
    rootContainer: HTMLElement;
    topbarContainer: HTMLElement;
    hamburgerMenuButton: HTMLButtonElement;
    leftMenu: HTMLUListElement;
    rightMenu: HTMLUListElement;

    public load(): void {
        this.findDOMElements();

        this._addEventHandlers();

        window.dispatchEvent(new Event('resize'));

        window.addEventListener('unload', this.onUnmountEventHandlers);
    }

    private findDOMElements(): void {
        this.rootContainer = this.dq(Selectors.ROOT_CONTAINER_SUBNAV) as HTMLElement;

        this.topbarContainer = this.dq(Selectors.TOPBAR_CONTAINER_SUBNAV) as HTMLElement;

        this.leftMenu = this.qs.call(this.rootContainer, Selectors.MENU_LEFT) as HTMLUListElement;

        this.rightMenu = this.qs.call(this.rootContainer, Selectors.MENU_RIGHT) as HTMLUListElement;

        this.hamburgerMenuButton = this.qs.call(this.rootContainer, Selectors.HAMBURGER_MENU) as HTMLButtonElement;
    }

    private _addEventHandlers(): void {
        // Bind & Register Event Handlers
        this.onHandleMenuClick = this.onHandleMenuClick.bind(this);
        this.onHandleMenuKeydown = this.onHandleMenuKeydown.bind(this);
        this.onHandleFocus = this.onHandleFocus.bind(this);
        this.onHandleHamburgerMenuClick = this.onHandleHamburgerMenuClick.bind(this);
        this.onHandleDocumentClick = this.onHandleDocumentClick.bind(this);
        this.onHandleWindowResize = this.onHandleWindowResize.bind(this);
        this.debouncedOnHandleWindowsResize = this.debouncedOnHandleWindowsResize.bind(this);
        this.onHandleHamburgerKeydown = this.onHandleHamburgerKeydown.bind(this);
        this.onHandleDocumentKeydown = this.onHandleDocumentKeydown.bind(this);
        this.onHandleLastMenuItemKeydown = this.onHandleLastMenuItemKeydown.bind(this);
        this.onHandleMenuItemKeyDownLastFirstItem = this.onHandleMenuItemKeyDownLastFirstItem.bind(this);
        this.onHandleFirstMenuItemKeydown = this.onHandleFirstMenuItemKeydown.bind(this);
        this.onUnmountEventHandlers = this.onUnmountEventHandlers.bind(this);
        this.onHandleSubMenuItemFocus = this.onHandleSubMenuItemFocus.bind(this);

        if (!this.rootContainer) {
            return;
        }

        // Dropdown Menu
        addListener(this.rootContainer, 'mousedown', Selectors.MENU_BUTTON, this.onHandleMenuClick);
        addListener(this.rootContainer, 'keydown', Selectors.MENU_BUTTON, this.onHandleMenuClick);

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_BUTTON)).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('click', this.onHandleFocus);
            menuBtn.addEventListener('focus', this.onHandleFocus);
        });

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_LINK)).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('click', this.onHandleFocus);
            menuBtn.addEventListener('focus', this.onHandleFocus);
        });

        Array.from(
            this.qsa.call(this.rootContainer, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
        ).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('focus', this.onHandleSubMenuItemFocus);
        });

        this.leftMenu.addEventListener('keydown', this.onHandleMenuItemKeyDownLastFirstItem);

        // Support Up/Down Arrow/Escape keys within submenu.
        Array.from(
            this.qsa.call(this.leftMenu, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
        ).forEach((menuBtn: Element): void => {
            menuBtn.addEventListener('keydown', this.onHandleMenuKeydown);
        });

        // Only bind hamburger events if hamburger element is on dom.
        // Set if "HEADER_SIMPLE" class to adjust the height of the header.
        if (this.hamburgerMenuButton && !this.hamburgerMenuButton.matches('p')) {
            this.hamburgerMenuButton.addEventListener('click', this.onHandleHamburgerMenuClick);
            this.hamburgerMenuButton.addEventListener('keydown', this.onHandleHamburgerKeydown);
        }

        // Window & Document
        document.addEventListener('click', (ev: MouseEvent): void => {
            this.onHandleDocumentClick(ev);
        });
        document.addEventListener('keydown', this.onHandleDocumentKeydown);

        window.addEventListener('resize', (): void => {
            this.debouncedOnHandleWindowsResize();
        });

        PubSub.subscribe(PUB_SUB_EVENTS.OPEN_HAMBURGER, (): void => {
            this._closeAllMenus();
        });

        PubSub.subscribe(PUB_SUB_EVENTS.ENABLE_SUBNAV_STICKY, (): void => {
            this.setStickyPosistioning(true);
        });

        PubSub.subscribe(PUB_SUB_EVENTS.DISABLE_SUBNAV_STICKY, (): void => {
            this.setStickyPosistioning(false);
        });
    }

    /**
     * Handles dropdown menu click
     * Opens the dropdown/accordion menu on click.
     * @param ev Keyboard or Mouse Event
     */
    onHandleMenuClick(ev: MouseEvent | KeyboardEvent): void {
        const menuToggleButton: EventTarget = ev.target;

        if (ev instanceof KeyboardEvent) {
            const keyCode: number = ev.keyCode ? ev.keyCode : ev.which;

            // Handle only Enter/Space within this method.
            if (ev.type === 'keydown' && keyCode !== keyCodeEnter && keyCode !== keyCodeSpace) return;
        }
        ev.stopPropagation();
        ev.preventDefault();

        const isDesktopViewport: MediaQueryList = window.matchMedia(`(min-width: ${Breakpoints.Desktop_Min}px)`);

        /**
         * Header uses dropdown menu for desktop resolution & accordion menu
         * for tablet/mobile resolution.
         *
         * Dropdown Menu classes - `is-dropdown-submenu-parent`, `is-dropdown-submenu`, `js-dropdown-active`
         * Accordion Menu classes - `is-accordion-submenu-parent`, `is-accordion-submenu`, `is-active`
         */
        if (isDesktopViewport.matches) {
            // Desktop resolution
            this._onHandleDropdownMenuClick(menuToggleButton as HTMLElement);
        } else {
            // Tablet, Mobile resolution
            this._onHandleAccordionMenuClick(menuToggleButton as HTMLElement);
        }

        (menuToggleButton as HTMLElement).focus();
    }

    onHandleSubMenuItemFocus(ev: FocusEvent): void {
        const currElm: Element = ev.target as Element;

        // Get all the open sub-menu's that are adjuscent to the current menu.
        const parent: HTMLElement = currElm.parentElement;
        const openMenus: HTMLElement[] | Element[] = siblings(
            parent,
            `${Selectors.DROPDOWN_MENU_PARENT}${Selectors.DROPDOWN_MENU_PARENT_OPEN}`
        );

        // Close then adjuscent sub-menu's after they have lost focus.
        if (openMenus && openMenus.length) {
            openMenus.forEach((openMenu: HTMLElement): void => {
                openMenu.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                const allOpenMenus: NodeListOf<HTMLElement> = this.qsa.call(
                    openMenu,
                    `${Selectors.DROPDOWN_MENU_SUBMENU}${Selectors.DROPDOWN_MENU_SUBMENU_OPEN}`
                );
                Array.from(allOpenMenus).forEach((selectedOpenMenu: HTMLElement): void => {
                    selectedOpenMenu.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);
                });
            });
        }
    }

    // Support Up/Down Arrow keys within submenu.
    onHandleMenuKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        // Handle keydown only for desktop viewport and not for accordion menu.
        if (this.IsLeftMenuAccordion && this.IsHamburgerMenuOpen) return;

        if (ev.keyCode === keyCodeArrowDown) {
            this._onHandleMenuKeydownArrowDownKey(ev);
        } else if (ev.keyCode === keyCodeArrowUp) {
            this._onHandleMenuKeydownArrowUpKey(ev);
        } else if (ev.keyCode === keyCodeEscape) {
            this._onHandleMenuKeydownEspaceKey(ev);
        }
    }

    onHandleMenuItemKeyDownLastFirstItem(ev: KeyboardEvent): void {
        const listOfVisibleChildren: Element[] | HTMLElement[] = (
            Array.from(this.qsa.call(this.leftMenu, 'li')) as any
        ).filter((child: HTMLElement): boolean => child.offsetParent !== null);

        const lengthOfVisibileChildren: number = listOfVisibleChildren.length;

        listOfVisibleChildren.forEach((child: HTMLElement, idx: number): void => {
            if ((child as Node).contains(ev.target as Node)) {
                // Hamburger menu uses absolute positioning.
                // On Shift + Tab, move the focus to hamburger menu button.
                if (idx === 0) {
                    this.onHandleFirstMenuItemKeydown(ev);
                }

                // Tab key on last menu element should close the sub-menu before moving
                // focus to right menu.
                if (idx === lengthOfVisibileChildren - 1) {
                    this.onHandleLastMenuItemKeydown(ev);
                }
            }
        });
    }

    /**
     * If this is last item on left menu [data-menu-left], then
     * Tab Key should Close the menu before moving tab focus to right menu.
     * @param ev Keyboard Event
     */
    onHandleLastMenuItemKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        if (ev.keyCode === keyCodeTab && !ev.shiftKey) {
            ev.stopPropagation();
            this._closeAllMenus();
        }
    }

    /**
     * Hamburger menu uses absolute positioning.
     * On Shift + Tab, Close the menu and shift tab focus to hamburger menu.
     * @param ev Keyboard Event
     */
    onHandleFirstMenuItemKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        if (ev.keyCode === keyCodeTab && ev.shiftKey && this.IsHamburgerMenuOpen) {
            this.hamburgerMenuButton.focus();
            ev.preventDefault();
            ev.stopPropagation();
        }
    }

    /**
     * Shift tab focus to next element within the sub-menu on Keyboard Arrow down keystoke.
     * @param ev - Keydown Event
     */
    _onHandleMenuKeydownArrowDownKey(ev: KeyboardEvent): void {
        if (!ev) return;

        const htmlTargetElement: HTMLAnchorElement | HTMLButtonElement = ev.target as
            | HTMLAnchorElement
            | HTMLButtonElement;

        // Find all the focussable elements under the closes submenu.
        let focussableSubMenuElements: HTMLElement[] = [];
        if (!this.IsLeftMenuAccordion) {
            const closestFocussableSubMenuElements: HTMLElement = htmlTargetElement.closest(
                Selectors.DROPDOWN_MENU_SUBMENU
            );
            focussableSubMenuElements = this.qsa.call(
                closestFocussableSubMenuElements,
                ':scope  > li > a, :scope > li > button'
            );
        } else {
            const closestFocussableSubMenuElements: HTMLElement = htmlTargetElement.closest(
                Selectors.ACCORDION_MENU_SUBMENU
            );
            focussableSubMenuElements = this.qsa.call(
                closestFocussableSubMenuElements,
                ':scope > li > a, :scope > li > button'
            );
        }

        let elementIndex: number = -1;
        Array.from(focussableSubMenuElements).forEach(function (el: HTMLElement, index: number): void {
            if (el === htmlTargetElement) {
                elementIndex = index;
            }
        });

        // Cycle through the menu items and focus the next item on the list.
        // If this is the last item in sub-menu then move focus to first item.
        focussableSubMenuElements[(elementIndex + 1) % focussableSubMenuElements.length].focus();

        ev.stopPropagation();
        ev.preventDefault();
    }

    /**
     * Shift tab focus to previous item on Arrow Up Keystroke.
     * @param ev Keyboard Event
     */
    _onHandleMenuKeydownArrowUpKey(ev: KeyboardEvent): void {
        if (!ev) return;

        const htmlTargetElement: HTMLAnchorElement | HTMLButtonElement = ev.target as
            | HTMLAnchorElement
            | HTMLButtonElement;

        // Find all the focussable elements under the closes submenu.
        let focussableSubMenuElements: HTMLElement[] = [];
        if (!this.IsLeftMenuAccordion) {
            focussableSubMenuElements = htmlTargetElement
                .closest(Selectors.DROPDOWN_MENU_SUBMENU)
                .querySelectorAll(':scope > li > a, :scope > li > button');
        } else {
            focussableSubMenuElements = htmlTargetElement
                .closest(Selectors.ACCORDION_MENU_SUBMENU)
                .querySelectorAll(':scope > li > a, :scope > li > button');
        }

        let elementIndex: number = -1;
        focussableSubMenuElements.forEach(function (el: HTMLElement, index: number): void {
            if (el === htmlTargetElement) {
                elementIndex = index;
            }
        });

        // If this is the first item in sub-menu, then move tab focus to last item.
        // Else, move tab focus to previos li element.
        if (elementIndex === 0) {
            focussableSubMenuElements[focussableSubMenuElements.length - 1].focus();
        } else {
            focussableSubMenuElements[elementIndex - 1].focus();
        }

        ev.stopPropagation();
        ev.preventDefault();
    }

    _onHandleMenuKeydownEspaceKey(ev: KeyboardEvent): void {
        if (!ev) return;

        ev.stopPropagation();

        const focussedElement: HTMLAnchorElement | HTMLButtonElement = ev.target as
            | HTMLAnchorElement
            | HTMLButtonElement;

        const parents: NodeListOf<HTMLElement> = focussedElement
            .closest('[data-menu-left]')
            .querySelectorAll(Selectors.DROPDOWN_MENU_PARENT);

        if (!parents) return;

        const topMostParentElement: HTMLElement | Element = parents[parents.length - 1];

        const menuToggleButton: HTMLElement =
            topMostParentElement && this.qs.call(topMostParentElement, `:scope > ${Selectors.MENU_BUTTON}`);

        if (menuToggleButton) {
            // Bug fix - https://msazure.visualstudio.com/One/_queries/edit/8351376/?triage=true
            this._closeAllMenus();
            menuToggleButton.setAttribute('aria-expanded', 'false');
            menuToggleButton.focus();
        }
    }

    onHandleHamburgerMenuClick(ev: MouseEvent): void {
        if (!ev.target) return;

        ev.stopPropagation();

        if (!this.hamburgerMenuButton && !this.leftMenu) return;

        this.hamburgerMenuButton.classList.toggle(CssClassNames.HAMBURGER_MENU_OPEN);

        this.hamburgerMenuButton.classList.contains(CssClassNames.HAMBURGER_MENU_OPEN)
            ? this.leftMenu.classList.add(CssClassNames.ACCORDION_MENU_EXPANDED)
            : this.leftMenu.classList.remove(CssClassNames.ACCORDION_MENU_EXPANDED);

        this.hamburgerMenuButton.classList.contains(CssClassNames.HAMBURGER_MENU_OPEN)
            ? this.hamburgerMenuButton.setAttribute('aria-expanded', 'true')
            : this.hamburgerMenuButton.setAttribute('aria-expanded', 'false');
    }

    /**
     * Handle Key down event on hamburger menu.
     * @param ev KeyboardEvent
     */
    onHandleHamburgerKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        if (ev.keyCode === keyCodeTab && !ev.shiftKey && this.IsHamburgerMenuOpen) {
            ev.preventDefault();

            const visibileChildren: HTMLElement[] = (
                Array.from(this.qsa.call(this.leftMenu, "button, a,  [tabindex]:not([tabindex='-1'])")) as any
            ).filter((child: HTMLElement): boolean => child.offsetParent !== null);

            if (visibileChildren && visibileChildren.length) {
                visibileChildren[0].focus();
            }
        } else if (ev.shiftKey && ev.keyCode === keyCodeTab) {
            ev.preventDefault();

            PubSub.publish(PUB_SUB_EVENTS.FOCUS_MAIN_NAV_HAMBURGER);

            if (this.IsHamburgerMenuOpen) {
                this._closeAllMenus();
            }
        } else if (ev.keyCode === keyCodeEscape) {
            if (this.IsHamburgerMenuOpen) {
                this._closeAllMenus();
            }
        }
    }

    onHandleFocus(ev: FocusEvent | KeyboardEvent): void {
        const focussedElement: EventTarget = ev.target;

        const level: number = this.getSubmenuLevel(focussedElement as HTMLElement);

        if (level === 3) return;

        this._closeOpenSubMenuAtSameLevel(ev);
    }

    /**
     * Catch all block for Document click event.
     *
     * Behavior -
     *  -   Close the hamburger/sub-menus if user clicks on non
     *      interactive menu elements.
     *
     *  -   Clicking on sub-menu won't fire this event as the captured event is
     *      not propagated up the chain.
     */
    onHandleDocumentClick(ev: MouseEvent): void {
        if (!ev) return;

        if ((ev.target as HTMLElement).matches('body')) return;

        const isFocusWithinHeaderContainer: boolean = this.rootContainer.contains(ev.target as Node);

        if (isFocusWithinHeaderContainer) return;

        this._closeAllMenus();
    }

    onHandleDocumentKeydown(ev: KeyboardEvent): void {
        if (!ev) return;

        const isFocusWithinNavigationMenu: boolean = this.leftMenu.contains(ev.target as Node);

        if (ev.keyCode === keyCodeEscape) {
            this._closeAllMenus();

            // Set focus to hamburger menu on escape key only if current focus is within accordion menu
            if (isFocusWithinNavigationMenu) {
                this.hamburgerMenuButton?.focus();
            }
        }
    }

    /**
     * Enable sticky behaviour if [data-sticky-header] is set for <header> element.
     */
    setStickyPosistioning(enableSticky: boolean): void {
        if (enableSticky) {
            if (!this.rootContainer?.classList.contains(CssClassNames.HEADER_STICKY)) {
                this.rootContainer?.classList.add(CssClassNames.HEADER_STICKY);
            }
        } else {
            this.rootContainer?.classList.remove(CssClassNames.HEADER_STICKY);
        }
    }

    onHandleWindowResize(): void {
        const isDesktopViewport: MediaQueryList = window.matchMedia(`(min-width: ${Breakpoints.Desktop_Min}px)`);

        // Mobile resolution
        if (!isDesktopViewport.matches) {
            // Close all menus when moving from desktop to mobile resolution
            if (!this.IsLeftMenuAccordion) {
                this._closeAllMenus();
            }

            this.leftMenu?.classList.remove(CssClassNames.DROPDOWN_MENU);
            this.leftMenu?.classList.add(CssClassNames.ACCORDION_MENU);

            // Switch from dropdown menu to accordion menu
            Array.from(this.qsa.call(this.leftMenu, '.is-dropdown-submenu-parent')).forEach(
                (innerElm: HTMLElement): void => {
                    innerElm?.classList.remove('is-dropdown-submenu-parent');
                    innerElm?.classList.add('is-accordion-submenu-parent');
                }
            );

            Array.from(this.qsa.call(this.leftMenu, '.is-dropdown-submenu')).forEach((innerElm: HTMLElement): void => {
                innerElm?.classList.remove('is-dropdown-submenu');
                innerElm?.classList.add('is-accordion-submenu', 'nested', 'bapi-hide');
            });
        }
        // Desktop resolution
        else {
            // Close all menus when moving from mobile to desktop resolution
            if (this.IsLeftMenuAccordion) {
                this._closeAllMenus();
            }

            this.leftMenu?.classList.remove(CssClassNames.ACCORDION_MENU);
            this.leftMenu?.classList.add(CssClassNames.DROPDOWN_MENU);

            // Switch from accordion menu to dropdown menu
            Array.from(this.qsa.call(this.leftMenu, '.is-accordion-submenu-parent')).forEach(
                (innerElm: HTMLElement): void => {
                    innerElm?.classList.remove('is-accordion-submenu-parent');
                    innerElm?.classList.add('is-dropdown-submenu-parent');
                }
            );

            Array.from(this.qsa.call(this.leftMenu, '.is-accordion-submenu.nested')).forEach(
                (innerElm: HTMLElement): void => {
                    innerElm?.classList.remove('is-accordion-submenu', 'nested', 'bapi-hide');
                    innerElm?.classList.add('is-dropdown-submenu');
                }
            );

            this.leftMenu?.classList.remove('bapi-accordion-menu');
            this.leftMenu?.classList.add('bapi-dropdown');
        }
    }

    private debouncedOnHandleWindowsResize: () => void = debounce(this.onHandleWindowResize, 100, { leading: true });

    /**
     *  Opens/Closes the the dropdown menu
     * @param currElm reference to the dropdown button element.
     */
    private _onHandleDropdownMenuClick(currElm: HTMLElement): void {
        const isMenuExpanded: boolean = currElm.parentElement?.classList.contains(
            CssClassNames.DROPDOWN_MENU_PARENT_OPEN
        );

        if (!isMenuExpanded) {
            currElm.parentElement?.classList.add(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void =>
                el?.classList.add(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN)
            );
        } else {
            currElm.parentElement?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void =>
                el?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN)
            );

            // Close all nested menus under this menu.
            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void => {
                Array.from(
                    this.qsa.call(el, `${Selectors.DROPDOWN_MENU_SUBMENU}${Selectors.DROPDOWN_MENU_SUBMENU_OPEN}`)
                ).forEach((innerElm: HTMLElement): void => {
                    innerElm?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);
                });
            });

            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void => {
                Array.from(
                    this.qsa.call(el, `${Selectors.DROPDOWN_MENU_PARENT}${Selectors.DROPDOWN_MENU_PARENT_OPEN}`)
                ).forEach((innerElm: HTMLElement): void => {
                    innerElm?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                });
            });

            siblings(currElm, Selectors.DROPDOWN_MENU_SUBMENU).forEach((el: Element): void => {
                Array.from(this.qsa.call(el, `${Selectors.MENU_BUTTON}`)).forEach((innerElm: HTMLElement): void => {
                    innerElm.setAttribute('aria-expanded', 'false');
                });
            });
        }

        currElm.setAttribute('aria-expanded', isMenuExpanded ? 'false' : 'true');
    }

    private _onHandleAccordionMenuClick(currElm: HTMLElement): void {
        const isMenuExpanded: boolean = currElm
            .closest(Selectors.ACCORDION_MENU_PARENT)
            ?.classList.contains(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

        if (isMenuExpanded) {
            currElm
                .closest(Selectors.ACCORDION_MENU_PARENT)
                ?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);
        } else {
            currElm.closest(Selectors.ACCORDION_MENU_PARENT)?.classList.add(CssClassNames.ACCORDION_MENU_PARENT_OPEN);
        }

        if (isMenuExpanded) {
            siblings(currElm, Selectors.ACCORDION_MENU_SUBMENU).forEach((el: Element): void => {
                el?.classList.remove(CssClassNames.ACCORDION_MENU_SUBMENU_OPEN);
                el?.classList.add(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);
            });
        } else {
            siblings(currElm, Selectors.ACCORDION_MENU_SUBMENU).forEach((el: Element): void => {
                el?.classList.add(CssClassNames.ACCORDION_MENU_SUBMENU_OPEN);
                el?.classList.remove(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);
            });
        }

        currElm.setAttribute('aria-expanded', isMenuExpanded ? 'false' : 'true');

        this.qs
            .call(this.leftMenu, `${Selectors.ACCORDION_MENU_PARENT}:not(${Selectors.ACCORDION_MENU_PARENT_OPEN})`)
            ?.classList.remove('has-accordion-submenu-active');

        const parents: HTMLElement[] | Element[] = parentsUntil(
            currElm,
            '[data-menu-left]',
            '.is-accordion-submenu-parent'
        );

        const isNestedAccordionMenu: boolean = parents.length > 1;

        if (parents && isNestedAccordionMenu) {
            const parent: HTMLElement | Element = parents[parents.length - 1];
            parent?.classList.add('has-accordion-submenu-active');
        }
    }

    private _closeOpenSubMenuAtSameLevel(ev: MouseEvent | KeyboardEvent | FocusEvent): void {
        const currElm: HTMLElement = ev.target as HTMLElement;

        // Dropdown menu's at same level.
        const menusAtSameLevel: HTMLElement[] | Element[] = siblings(
            currElm.parentElement,
            '.is-dropdown-submenu-parent'
        );

        if (menusAtSameLevel && menusAtSameLevel.length) {
            menusAtSameLevel.forEach((element: Element): void => {
                element.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                this.qs
                    .call(element, Selectors.DROPDOWN_MENU_SUBMENU)
                    ?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);
                this.qs
                    .call(element, Selectors.DROPDOWN_MENU_PARENT_OPEN)
                    ?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);
                this.qs.call(element, Selectors.MENU_BUTTON)?.setAttribute('aria-expanded', 'false');
            });
        }

        // Accordion menu's at same level.
        const accordionsAtSameLevel: HTMLElement[] | Element[] = siblings(
            currElm.parentElement,
            '.is-accordion-submenu-parent, .is-menu-link'
        );

        if (accordionsAtSameLevel && accordionsAtSameLevel.length) {
            accordionsAtSameLevel.forEach((element: Element): void => {
                element.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

                // Close all sub-menus under this menu.
                Array.from(this.qsa.call(element, Selectors.ACCORDION_MENU_PARENT)).forEach((el: HTMLElement): void => {
                    el?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);
                });
                Array.from(this.qsa.call(element, Selectors.ACCORDION_MENU_SUBMENU)).forEach(
                    (el: HTMLElement): void => {
                        el?.classList.remove(CssClassNames.ACCORDION_MENU_SUBMENU_OPEN);
                        el?.classList.add(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);
                    }
                );
                Array.from(this.qsa.call(element, Selectors.MENU_BUTTON)).forEach((el: HTMLElement): void => {
                    el?.setAttribute('aria-expanded', 'false');
                });
            });
        }
    }

    /**
     * Closes all the open accordion and dropdown menu.
     * By default the hamburger menu is closed if open.
     */
    private _closeAllMenus(): void {
        // Dropdown Menu
        this.qs
            .call(this.rootContainer, `${Selectors.DROPDOWN_MENU_PARENT}${Selectors.DROPDOWN_MENU_PARENT_OPEN}`)
            ?.classList.remove(CssClassNames.DROPDOWN_MENU_PARENT_OPEN);

        this.qs
            .call(this.rootContainer, `${Selectors.DROPDOWN_MENU_SUBMENU}${Selectors.DROPDOWN_MENU_SUBMENU_OPEN}`)
            ?.classList.remove(CssClassNames.DROPDOWN_MENU_SUBMENU_OPEN);

        // Accordion Menus
        this.qs
            .call(this.rootContainer, `${Selectors.ACCORDION_MENU_PARENT}${Selectors.ACCORDION_MENU_PARENT_OPEN}`)
            ?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

        this.qs
            .call(this.rootContainer, `${Selectors.ACCORDION_MENU_SUBMENU}${Selectors.ACCORDION_MENU_SUBMENU_OPEN}`)
            ?.classList.remove(CssClassNames.ACCORDION_MENU_PARENT_OPEN);

        this.qs
            .call(this.rootContainer, `${Selectors.ACCORDION_MENU_SUBMENU}${Selectors.ACCORDION_MENU_SUBMENU_OPEN}`)
            ?.classList.add(CssClassNames.ACCORDION_MENU_COLLAPSE_CLASS);

        // bug fix - https://dev.azure.com/dynamicscrm/CXP/_workitems/edit/1852689
        this.qs.call(this.rootContainer, Selectors.MENU_BUTTON)?.setAttribute('aria-expanded', 'false');

        /**
         * If hamburger menu is open, then close it.`
         */
        if (this.IsHamburgerMenuOpen) {
            this.hamburgerMenuButton?.click();
        }
    }

    /**
     * Gets the dropdown nesting level
     * @param element dropdown menu toggle button
     * Can be 1, 2, or 3
     */
    private getSubmenuLevel(element: HTMLElement): number {
        return parentsUntil(element, '[data-menu-left]', Selectors.DROPDOWN_MENU_PARENT).length;
    }

    /**
     * Returns true if hamburger manu is open.
     */
    get IsHamburgerMenuOpen(): boolean {
        return (
            this.hamburgerMenuButton && this.hamburgerMenuButton.classList.contains(CssClassNames.HAMBURGER_MENU_OPEN)
        );
    }

    /**
     * Returns true if left menu is rendered as a accordion menu.
     */
    get IsLeftMenuAccordion(): boolean {
        return this.leftMenu && this.leftMenu.classList.contains(CssClassNames.ACCORDION_MENU);
    }

    /**
     * Unmount/un-register all the event handlers.
     */
    onUnmountEventHandlers(): void {
        // Dropdown Menu
        removeListener(this.rootContainer, 'mousedown', Selectors.MENU_BUTTON, this.onHandleMenuClick);
        removeListener(this.rootContainer, 'keydown', Selectors.MENU_BUTTON, this.onHandleMenuClick);

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_BUTTON)).forEach((menuBtn: Element): void => {
            menuBtn.removeEventListener('click', this.onHandleFocus);
            menuBtn.removeEventListener('focus', this.onHandleFocus);
        });

        Array.from(this.qsa.call(this.rootContainer, Selectors.MENU_LINK)).forEach((menuBtn: Element): void => {
            menuBtn.removeEventListener('click', this.onHandleFocus);
            menuBtn.removeEventListener('focus', this.onHandleFocus);
        });

        Array.from(
            this.qsa.call(this.rootContainer, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
        ).forEach((menuBtn: Element): void => {
            menuBtn.removeEventListener('focus', this.onHandleSubMenuItemFocus);
        });

        this.leftMenu.removeEventListener('keydown', this.onHandleMenuItemKeyDownLastFirstItem);

        // Support Up/Down Arrow/Escape keys within submenu.
        Array.from(
            this.qsa.call(this.leftMenu, '.is-dropdown-submenu > li > a, .is-dropdown-submenu > li > button')
        ).forEach((menuBtn: Element): void => {
            menuBtn.removeEventListener('keydown', this.onHandleMenuKeydown);
        });

        // Only bind hamburger events if hamburger element is on dom.
        // Set if "HEADER_SIMPLE" class to adjust the height of the header.
        if (this.hamburgerMenuButton && !this.hamburgerMenuButton.matches('p')) {
            this.hamburgerMenuButton.removeEventListener('click', this.onHandleHamburgerMenuClick);
            this.hamburgerMenuButton.removeEventListener('keydown', this.onHandleHamburgerKeydown);
        }

        // Window & Document
        document.removeEventListener('click', (ev: MouseEvent): void => {
            this.onHandleDocumentClick(ev);
        });
        document.removeEventListener('keydown', this.onHandleDocumentKeydown);

        window.removeEventListener('resize', (): void => {
            this.debouncedOnHandleWindowsResize();
        });
    }
}
