export const Selectors: any = {
    // ROOT
    ROOT_CONTAINER: '#bapi-header',
    TOPBAR_CONTAINER: '#bapi-header > .bapi-top-bar',
    ROOT_CONTAINER_SUBNAV: '#bapi-header-subnavigation',
    TOPBAR_CONTAINER_SUBNAV: '#bapi-header-subnavigation > .bapi-top-bar',

    // LOGO, BRAND, SEPARATOR
    LOGO: '.bapi-top-bar__logo',
    SEPARATOR: '.bapi-top-bar__separator',
    BRAND: '.bapi-top-bar__brand',
    PRODUCT: '.bapi-top-bar__sub-product',
    ME_CONTROL: '#meControl',
    ME_CONTROL_SIGN_IN_TRIGGER: '#mectrl_main_trigger',

    // MENU
    MENU_BUTTON: '.bapi-menu-toggle-button',
    MENU_LEFT: '[data-menu-left]',
    MENU_RIGHT: '[data-menu-right]',
    HAMBURGER_CONTAINER: '.hamburger-container',
    HAMBURGER_MENU: '.hamburger-container .hamburger-container__hamburger',

    MENU_LINK_PARENT: '.is-menu-link',
    MENU_LINK: '.is-menu-link > a',
    MENU_ELEMENTS: 'li:not(:hidden)',
    MENU_LEFT_LAST_ELEMENT: 'li:not(:hidden)',
    MENU_LEFT_FIRST_ELEMENT: 'li:not(:hidden)',
    MORE_MENU: '.is-dropdown-submenu-parent.more',
    MORE_MENU_LIST: '#more-menu-list',
    MORE_MENU_BUTTON: '#bapi-more-menu-button',

    // DROP DOWN MENU
    DROPDOWN_MENU_PARENT: '.is-dropdown-submenu-parent',
    DROPDOWN_MENU_PARENT_OPEN: '.is-active',
    DROPDOWN_MENU_SUBMENU: '.is-dropdown-submenu',
    DROPDOWN_MENU_SUBMENU_OPEN: '.js-dropdown-active',
    DROPDOWN_MENU_SUBMENU_SECOND_LEVEL: '.nested-submenu',
    DROPDOWN_MENU_MORE_MENU_NESTED: '.moremenu-nested-submenu',

    // ACCORDION MENU
    ACCORDION_MENU_PARENT: '.is-accordion-submenu-parent',
    ACCORDION_MENU_PARENT_OPEN: '.is-active',
    ACCORDION_MENU_SUBMENU: '.is-accordion-submenu',
    ACCORDION_MENU_SUBMENU_OPEN: '.is-active',
    ACCORDION_MENU_SUBMENU_SECOND_LEVEL: '.nested-submenu',
    ACCORDION_MENU_TOGGLE_CLASS: 'bapi-hide',
};

export const CssClassNames: any = {
    HEADER_STICKY: 'bapi-header--sticky',
    HEADER_SIMPLE: 'bapi-header--simple',
    DROPDOWN_MENU: 'bapi-dropdown',
    ACCORDION_MENU: 'bapi-accordion-menu',
    ACCORDION_MENU_EXPANDED: 'bapi-expanded',
    ACTIVE: 'active',
    DISABLED: 'disabled',

    // DROP DOWN MENU
    DROPDOWN_MENU_PARENT_OPEN: 'is-active',
    DROPDOWN_MENU_SUBMENU_OPEN: 'js-dropdown-active',
    DROPDOWN_MENU_SUBMENU: 'is-dropdown-submenu',
    DROPDOWN_MENU_SECOND_LEVEL: 'nested-submenu',
    DROPDOWN_MENU_MORE_MENU_NESTED: 'moremenu-nested-submenu',
    DROPDOWN_MENU_PARENT: 'is-dropdown-submenu-parent',

    // ACCORDION MENU
    ACCORDION_MENU_PARENT_OPEN: 'is-active',
    ACCORDION_MENU_SUBMENU_OPEN: 'is-active',
    ACCORDION_MENU_COLLAPSE_CLASS: 'bapi-hide',
    ACCORDION_MENU_SUBMENU: 'is-accordion-submenu',
    ACCORDION_MENU_PARENT: 'is-accordion-submenu-parent',

    HAMBURGER_MENU_OPEN: 'is-active',
    HAMBURGER_CONTAINER_OPEN: 'open',
};

export const MoreMenuMinSpace: number = 60;

export const enum Breakpoints {
    Desktop_Min = 1024,
}

export const HeaderHeight: number = 68;

export const SubMenuWidth: number = 256;

// Min space below which more menu opening direction is reversed.
export const MinSpaceBeforeDirectionReverse: number = 30;
